
//normal
$color-primary: #44C606
$color-white: #FFFFFF
$color-black: #000000
$color-brown: #976F53
$color-pink: #EB008B
$color-red: #C12A21
$color-yellow: #FBC527
$color-blue: #013171
$color-orange: #FF5C00
$color-green: #009A35
$color-ligthBlue: #009EE5
$color-calipso: #009F9A
$color-gold: #B7982A
$color-purple: #860085
$color-gray: #857774

//dark
$color-primary-dark: #3CAD07
$color-green-dark: #007E32
$color-black-dark: #333333
$color-brown-dark: #7D5C45
$color-blue-dark: #012657
$color-orange-dark: #E65400
$color-pink-dark: #D1007A
$color-ligthBlue-dark: #008BCC
$color-red-dark: #A8241D
$color-yellow-dark: #E0B124
$color-gold-dark: #9E8424
$color-purple-dark: #6E006E
$color-white-dark: #eeeeee

//estados

$color-disabled: #E8E8E8

//otros
$color-white-light: #F2F2F2
$color-white-dark: #dfdfdf
$color-gris-light: #989898
$color-gray-light: #fdfdfd
$color-gray-dark: #3B3B3B
$color-gray-darkest: #5D5D5D

$color-gray-tecla-numero: #939393
$color-gray-tecla-numero-activo: #7a7a7a
$color-gray-tecla-letra: #505050

$color-red-botones: #FF0800
$color-secondary: #F7AB00
$color-azul-strong: #005ACE

