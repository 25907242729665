.Map-container {
  height: 100%;
  width: 100%;
}

.Map-warning {
  background-color: transparent;
  color: black;
  font-size: 40px;
  height: 100px;
  margin-top: 20px;
  position: absolute;
  text-align: center;
  width: 400px;
}

.Map-holder {
  height: 100%;
  position: relative;
  width: 100%;
}

.wrap-combos {
  left: 50px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  width: 300px;
}

.wrap-ddl-combos {
  left: 50px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  width: 300px;
}

.wrap-ddl-combos-to {
  left: 50px;
  overflow: hidden;
  position: absolute;
  top: 90px;
  width: 300px;
}

.wrap-combos select {
  font-size: 20px;
  height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  width: 100%;
}

.wrap-path {
  bottom: 50px;
  left: 50px;
  position: absolute;
}

.floor-levels {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: absolute;
  z-index: 2;

  .MuiButton-root {
    width: 93px;
    height: 40px;

    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 12px;
    line-height: 15px;
    font-family: Roboto;
    font-style: normal;
    margin-bottom: 3px;
  }

  .MuiButton-root:first-child {
    width: 93px;
    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 50px;
  }

  .MuiButton-root:last-child {
    width: 93px;
    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 50px;
  }

  button.active {
    background: #0251b6 !important;
    border-radius: 50px !important;
    border: solid 1px #0251b6;

    .MuiButton-label {
      width: 65.63px;
      height: 20.95px;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
  }

  button.active:disabled {
    background: #0251b6 !important;
    border-radius: 50px !important;
    border: solid 1px #0251b6;

    .MuiButton-label {
      width: 60.63px;
      height: 20.95px;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
  }

  .MuiButton-root.Mui-disabled {
    background: #ffffff !important;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 50px;
    color: #c2c2c2;

    .MuiButton-label {
      color: #c2c2c2;
    }
  }
}

.floor-levels button {
  background-color: #ffffff;
  color: #0251b6;
}

.repeat-route-button {
  bottom: 1em;
  position: absolute;
  right: 1em;
  z-index: 200;

  .MuiButton-containedPrimary {
    min-width: 93px;
    padding: 0;
    width: 93px;
    width: 140px;
    height: 40px;

    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 50px;

    .MuiButton-label {
      width: 96px;
      height: 18.95px;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px !important;
      line-height: 18px;
      text-align: center;

      color: #0251b6;

      .txt-icono {
        font-size: 15px;
        text-transform: capitalize;
      }
    }
  }

  .txt-icono:hover {
    font-size: 15px;
    text-transform: capitalize;
    color: #ffffff;
  }

  .MuiButton-containedPrimary:hover {
    background-color: #0251b6;

    .txt-icono {
      font-size: 15px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  .MuiButton-containedPrimary:disabled {
    background: #ffffff !important;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 50px;

    .txt-icono {
      color: #c2c2c2 !important;
    }
  }
}

.accesible-route-button {
  bottom: 4.2em;
  position: absolute;
  right: 1em;
  z-index: 200;

  min-width: 93px;
  padding: 8px;
  width: 93px;
  width: 140px;
  height: 40px;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  background: #ffffff;
  border: 1px solid #02b644;
  box-sizing: border-box;
  border-radius: 50px;
  .button-accesible {
    text-align: center;
    span {
      text-transform: capitalize;
      font-size: 16px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      color: #02b644;
    }
  }
}
.button-accesible-disabled {
  background: #ffffff !important;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 50px;
  span {
    color: #c2c2c2 !important;
  }
}

.button-accesible-active {
  background: #28a72a !important;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 50px;
  span {
    color: #ffffff !important;
  }
}

.path-instructions {
  background-color: white;
  border-radius: 5%;
  display: grid;
  font-size: 2em;
  height: 13vh;
  margin: 2em;
  padding: 2em;
  position: absolute;
  right: 0;
  text-align: center;
  width: 20vw;
  z-index: 200;
}

.modal-error-body {
  width: 75%;
  margin: auto;
  margin-top: 25vh;
  border-radius: 50px;
  text-align: center;
  padding: 10% 5%;
  font-size: 3.3vh;
  background-color: white;

  @media (min-device-width: 320px) and (max-device-width: 1024px) {
    border-radius: 20px;
  }

  .img-error span {
    color: red;
    font-size: 4em;
    @media (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      font-size: 3em;
    }
  }

  .button-modal {
    background-color: green;
    border-radius: 10%;
    font-size: 1em;
    margin-top: 1em;
  }

  .icon-close {
    float: right;
    padding: 10px;
    position: absolute;
    display: flex;
    top: 26%;
    right: 18%;
  }
}

.map-ui-container .zoom-buttons .component {
  position: absolute;

  [data-zoom] {
    width: 38px;
    height: 38px;

    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 0px 0px 20px 20px;
    transform: rotate(180deg);

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0251b6;
  }

  [data-zoom]:last-child {
    margin-top: 2px;
    width: 38px;
    height: 38px;

    background: #ffffff;
    border: 1px solid #0251b6;
    box-sizing: border-box;
    border-radius: 0px 0px 20px 20px;
    transform: rotate(360deg);

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0251b6;
  }

  [data-zoom]:hover {
    background-color: #184ea8;
    border-color: #184ea8;
    color: #fff;
  }
}

div:focus {
  outline: none;
}

.spinnerMap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.repeat-route-button {
  right: 0.8em;
}

.floor-levels {
  .MuiButton-root.active {
    border: solid 1px #0251b6;
  }
}

[component] {
  left: 95.6% !important;
}

.marker-map {
  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  height: 20px;
  transform: rotate(-45deg);
  width: 20px;
}

.marker-map::after {
  background: #fff;
  border-radius: 50%;
  content: '';
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .Map-container {
    height: 100%;
    width: 100%;
  }

  .Map-warning {
    background-color: transparent;
    color: black;
    font-size: 40px;
    height: 100px;
    margin-top: 20px;
    position: absolute;
    text-align: center;
    width: 400px;
  }

  .Map-holder {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .wrap-combos {
    left: 50px;
    overflow: hidden;
    position: absolute;
    top: 50px;
    width: 300px;
  }

  .wrap-ddl-combos {
    left: 50px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 300px;
  }

  .wrap-ddl-combos-to {
    left: 50px;
    overflow: hidden;
    position: absolute;
    top: 90px;
    width: 300px;
  }

  .wrap-combos select {
    font-size: 20px;
    height: 40px;
    margin-bottom: 20px;
    text-transform: capitalize;
    width: 100%;
  }

  .wrap-path {
    bottom: 50px;
    left: 50px;
    position: absolute;
  }

  .floor-levels {
    display: flex;
    flex-direction: column;
    padding: 1em;
    position: absolute;
    z-index: 2;

    .MuiButton-root {
      width: 200px;
      height: 75px;

      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 50px;
      font-size: 25px;
      line-height: 15px;
      font-family: Roboto;
      font-style: normal;
      margin-bottom: 10px;
    }

    .MuiButton-root:first-child {
      width: 200px;
      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 50px;
    }

    .MuiButton-root:last-child {
      width: 200px;
      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 50px;
    }

    button.active {
      background: #0251b6 !important;
      border-radius: 50px !important;
      border: solid 1px #0251b6;

      .MuiButton-label {
        width: 100%;
        height: 20.95px;

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 15px;
        color: #ffffff;
      }
    }

    button.active:disabled {
      background: #0251b6 !important;
      border-radius: 50px !important;
      border: solid 1px #0251b6;

      .MuiButton-label {
        width: 100%;
        height: 20.95px;

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 15px;
        color: #ffffff;
      }
    }

    .MuiButton-root.Mui-disabled {
      background: #ffffff !important;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      border-radius: 50px;
      color: #c2c2c2;

      .MuiButton-label {
        color: #c2c2c2;
      }
    }
  }

  .floor-levels button {
    background-color: #ffffff;
    color: #0251b6;
  }

  .repeat-route-button {
    bottom: 1em;
    position: absolute;
    right: 1em;
    z-index: 200;

    .MuiButton-containedPrimary {
      min-width: 93px;
      padding: 0;
      width: 93px;
      width: 140px;
      height: 40px;

      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 50px;

      .MuiButton-label {
        width: 96px;
        height: 18.95px;

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px !important;
        line-height: 18px;
        text-align: center;

        color: #0251b6;

        .txt-icono {
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }

    .txt-icono:hover {
      font-size: 15px;
      text-transform: capitalize;
      color: #ffffff;
    }

    .MuiButton-containedPrimary:hover {
      background-color: #0251b6;

      .txt-icono {
        font-size: 15px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }

    .MuiButton-containedPrimary:disabled {
      background: #ffffff !important;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      border-radius: 50px;

      .txt-icono {
        color: #c2c2c2 !important;
      }
    }
  }

  .accesible-route-button {
    bottom: 4.2em;
    position: absolute;
    right: 1em;
    z-index: 200;

    min-width: 93px;
    padding: 8px;
    width: 93px;
    width: 140px;
    height: 40px;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    background: #ffffff;
    border: 1px solid #02b644;
    box-sizing: border-box;
    border-radius: 50px;
    .button-accesible {
      text-align: center;
      span {
        text-transform: capitalize;
        font-size: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        color: #02b644;
      }
    }
  }
  .button-accesible-disabled {
    background: #ffffff !important;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 50px;
    span {
      color: #c2c2c2 !important;
    }
  }

  .button-accesible-active {
    background: #28a72a !important;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 50px;
    span {
      color: #ffffff !important;
    }
  }

  .path-instructions {
    background-color: white;
    border-radius: 5%;
    display: grid;
    font-size: 2em;
    height: 13vh;
    margin: 2em;
    padding: 2em;
    position: absolute;
    right: 0;
    text-align: center;
    width: 20vw;
    z-index: 200;
  }

  .modal-error-body {
    width: 75%;
    margin: auto;
    margin-top: 25vh;
    border-radius: 50px;
    text-align: center;
    padding: 10% 5%;
    font-size: 3.3vh;
    background-color: white;

    border-radius: 20px;

    .img-error span {
      color: red;
      font-size: 4em;
    }

    .button-modal {
      background-color: green;
      border-radius: 10%;
      font-size: 1em;
      margin-top: 1em;
    }

    .icon-close {
      float: right;
      padding: 10px;
      position: absolute;
      display: flex;
      top: 26%;
      right: 18%;
    }
  }

  .map-ui-container .zoom-buttons .component {
    position: absolute;

    [data-zoom] {
      width: 80px;
      height: 80px;

      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 0px 0px 20px 20px;
      transform: rotate(180deg);

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      color: #0251b6;
    }

    [data-zoom]:last-child {
      margin-top: 2px;
      width: 80px;
      height: 80px;

      background: #ffffff;
      border: 1px solid #0251b6;
      box-sizing: border-box;
      border-radius: 0px 0px 20px 20px;
      transform: rotate(360deg);

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      color: #0251b6;
    }

    [data-zoom]:hover {
      background-color: #184ea8;
      border-color: #184ea8;
      color: #fff;
    }
  }

  div:focus {
    outline: none;
  }

  .spinnerMap {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .repeat-route-button {
    right: 0.8em;
    button {
      height: 100px !important;
      width: 300px !important;

      span {
        font-size: 30px !important;
        width: 300px;
        line-height: 1;
      }
    }
  }

  .floor-levels {
    .MuiButton-root.active {
      border: solid 1px #0251b6;
    }
  }

  [component] {
    left: 95.6% !important;
  }

  .marker-map {
    border-radius: 50% 50% 50% 0;
    border: 4px solid #fff;
    height: 40px;
    transform: rotate(-45deg);
    width: 40px;
  }

  .marker-map::after {
    background: #fff;
    border-radius: 50%;
    content: '';
    height: 15px;
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    width: 15px;
  }
}
