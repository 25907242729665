.modalAccesibilidad {
  width: 75% !important;
  margin: auto !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 0 7% 7% !important;
  font-size: 3.3vh !important;
  background-color: #ffffff !important;
  height: 35% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  div {
    svg {
      position: relative;
      top: 0px;
      width: 165px !important;
      height: 165px !important;
    }
  }
}

@media screen and (max-width: 415px) and (min-width: 400px) {
  .modalAccesibilidad {
    width: 80% !important;
    margin: auto !important;
    border-radius: 50px !important;
    text-align: center !important;
    padding: 0 7% 9% !important;
    font-size: 3.3vh !important;
    background-color: #ffffff !important;
    height: 40% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;

    div {
      svg {
        position: relative;
        top: 40px;
        width: 135px !important;
        height: 135px !important;
      }
    }
  }
}

@media screen and (max-width: 400px) and (min-width: 200px) {
  .modalAccesibilidad {
    width: 80% !important;
    margin: auto !important;
    border-radius: 50px !important;
    text-align: center !important;
    padding: 0 7% 9% !important;
    font-size: 3.3vh !important;
    background-color: #ffffff !important;
    height: 35% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;

    div {
      svg {
        position: relative;
        top: 20px;
        width: 125px !important;
        height: 125px !important;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .modalAccesibilidad {
    width: 80% !important;
    margin: auto !important;
    border-radius: 50px !important;
    text-align: center !important;
    padding: 0 7% 9% !important;
    font-size: 3.3vh !important;
    background-color: #ffffff !important;
    height: 40% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;

    div {
      width: 100% !important;
      svg {
        width: 75% !important;
      }
    }
  }
}
