@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.bg-AZUL {
  background-color: #005ace;
}
.bg-AZUL-2 {
  background-color: #0251b7;
}
.bg-ROJO {
  background-color: #f70c05;
}

.cabecera .contenido.mapa {
  color: $color-white;
  padding: 0px 50px;
  margin-top: 30px;
}

.cabecera.fnd-tienda {
  background-position: bottom right;
  background-size: 100%;
  background-repeat: no-repeat;
  position: initial !important;
}

.cabecera .contenido.mapa .tipo-local {
  font-size: 20px;
}

.cabecera .contenido.mapa .nombre-local {
  font-size: 50px;
  font-weight: bold;
}

.cabecera .contenido.mapa .informacion-adicional {
  font-size: 16px;
  float: left !important;
}

.estado-circle {
  border-radius: 100%;
  height: 15px;
  width: 15px;
  background-color: white;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.informacion-adicional .outlined-red {
  border: solid 1px $color-red;
  color: $color-red;
}

.informacion-adicional .outlined-green {
  border: solid 1px $color-primary;
  color: $color-primary;
}

.informacion-adicional .outlined-blue {
  border: solid 1px $color-azul-strong;
  color: $color-azul-strong;
}

.informacion-adicional .outlined-yellow {
  border: solid 1px $color-gold;
  color: $color-gold;
}

.estado-circle.open {
  background-color: $color-primary;
}

.estado-circle.close {
  background-color: $color-red-botones;
}

.wrap-imagen-cabecera {
  width: 100%;
  text-align: left;
  margin-top: 20px;
}

.wrap-imagen-cabecera img {
  width: 80%;
}

.seccion-mapa {
  overflow: hidden !important;
  background-color: #fff;
  height: 100vh;
  position: relative;
}

.wrap-indicaciones {
  position: absolute;
  z-index: 100;
  right: 30px;
  top: 30px;
}

.footer-controles {
  position: absolute;
  z-index: 100;
  right: 30px;
  bottom: 30px;
}

.cuadro-indicaciones {
  background-color: white;
  padding: 30px 10px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  width: 200px;
  text-align: center;
  color: $color-azul-strong;
  font-size: 20px;
  border-radius: 10px;
}

.flecha-indicaciones .material-icons {
  font-size: 100px;
}

.enviar-ruta {
  margin-top: 20px;
}

.btn-enviar-ruta {
  background-color: black !important;
  color: white !important;
  height: 60px;
  border-radius: 90px !important;
  text-transform: none !important;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.btn-enviar-ruta .material-icons {
  margin-left: 5px;
}

.btn-mapa {
  background-color: black !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  // box-shadow: 3px 3px 6px rgba(0,0,0,0.3);
  box-shadow: none !important;
  width: 120px !important;
  height: 120px !important;
  text-align: center;
}

.btn-mapa .material-icons {
  font-size: 50px;
}

.btn-mapa .icono {
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.btn-mapa .texto {
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 40px;
  line-height: 15px;
  font-size: 14px;
}

.btn-mapa.MuiButtonBase-root {
  display: initial;
}

.btn-repetir {
  background-color: $color-red-botones !important;
}

.btn-accesible {
  background-color: $color-secondary !important;
}
